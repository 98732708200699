<template>
  <ListFrame
    :getPageFn="getPage"
    :immediateInit="false"
    :viewConf="{showLvl:true, showTitle:true, showReorder:false, itemsDelAble:delAble?{delFn}:false, selectAble:isAudio}"
    :customedParams="customedParams"
  >
    <template slot="batch-opterate" slot-scope="{items}">
      <Button
        v-if="isAudio"
        :disabled="!items.length"
        type="primary"
        style="margin-left:1.5em;"
        @click="clickUploadAudios(items)"
      >
        上传音频
        <Drawer title="选择配音" :mask-closable="false" v-model="showAudioDrawer" width="600">
          <UploadAudioBatch
            v-if="showAudioDrawer"
            @audioUploadFinished="onAudioFinished"
            :hbList="items"
          />
        </Drawer>
      </Button>
    </template>
    <template slot="conditions" slot-scope="{requestFn}">
      <FirstCategory      
        classification="game"
        class="typeSelect"
        v-model="customedParams.contentType"
        @input="requestFn()"
      />
      <ReceiveStatusSelect v-model="customedParams.waitStatus" @input="requestFn"
          class="m-r-md"
          style="width:13em;"/>
      </template>
    
    <template slot-scope="{item}">
      <TaskItem
        v-if="item.contentType ==='cnIdiomComb'"
        :idomComb="item"
        style="margin-bottom:1em;flex:1"
      >
        <template v-slot:tags-editor>
          <ButtonsForWaitdealList :hb="item" @to-handle="toPage(item)"/>          
        </template>
      </TaskItem>
      <FlyingShowItem v-else :data="item">
        <template v-slot:tags-editor>
          <ButtonsForWaitdealList :hb="item" @to-handle="toPage(item)"/>
        </template>
      </FlyingShowItem>
    </template>
  </ListFrame>
</template>

<script>
import TaskItem from "./TaskItem";
import FlyingShowItem from "./FlyingShowItem";
import { gemeTaskList, _configType,delList } from "./services";
import waitDealMixin from "../page-list/waitDealMixin";
// todo
export default {
  components: { TaskItem, FlyingShowItem },
  mixins: [waitDealMixin],
  data() {
    return {
      customedParams: { contentType: "cn",waitStatus:2 },
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    delAble() {
      return [4, 5, 6, 7].includes(this.roleCode);
    },
    isAudio() {
      return this.roleCode === 5;
    },
  },
  methods: {
    getPage(params) {
      params.taskName = 0;
      params.category = params.contentType;
      params.gameType = _configType[params.contentType];
      return gemeTaskList(params).then((res) => {
        res.rows = res.rows.filter((ele) => !!ele);
        res.rows.forEach((ele) => {
          ele.gameType = _configType[ele.contentType];
        });
        return res;
      });
    },
    delFn(list, reason) {
      return delList(list, reason).catch((err) => {
        this.$Message.warning("[后台提示]" + err);
        throw err;
      });
    },

    toPage(item) {
      switch (item.taskName) {
        case 6:
          this.$router.push({
            path: "/gameVerify6",
            query: {
              taskId: item.taskId,
              bizId: item.bizId,
              processId: item.processInstanceId,
              contentType: item.contentType,
            },
          });
          break;
        case 5:
          this.$router.push({
            path: "/edit4audio",
            query: {
              taskId: item.taskId,
              bizId: item.bizId,
              processId: item.processInstanceId,
              contentType: item.contentType,
            },
          });
          break;
      }
    },
  },
  beforeMount() {},
};
</script>

<style scoped>
.typeSelect {
  width: 200px;
  margin-right: 20px;
}
</style>